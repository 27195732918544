import { Button, Grid, TextField, Typography } from '@mui/material'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Rating } from "react-simple-star-rating";
import Swal from 'sweetalert2';
import { startNewEvaluacion } from '../../../../store/evaluaciones/thunk';


import '../styles/evaluationForm.css';

export const EvaluationForm = () => {
  const dispatch = useDispatch();

  const [rating, setRating] = useState(0)
  const [evaluation, setEvaluation] = useState({
    location: '',
    comentario: '',
  })

  const { location, comentario } = evaluation;

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  }

  const handleSetEvaluation = (e) => {
    setEvaluation({
        ...evaluation,
        [e.target.name] : e.target.value
        })
    }

  const handleEvaluationSubmit = (e) => {
        e.preventDefault();
        if( location.trim() === '' || rating === 0){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Todos los campos son obligatorios',
          })
          return;
        }
        try {
          dispatch(startNewEvaluacion({location, comentario, rating}));
          setEvaluation({
            location: '',
            comentario: '',
          })
          setRating(0)
          Swal.fire({
            icon: 'success',
            title: 'Evaluación enviada',
            text: 'Gracias por evaluar nuestro servicio',
          })
        } catch (error) {
          console.log(error);
        }
    }

  return (
    <Grid container direction='column' alignItems='center' justifyContent='center'>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
                variant='h3'
                justifyContent='center'
                sx={{ textAlign: 'center', marginTop: '3rem' }}
            >
                Tu oponión es importante, envíanos tu evaluación
            </Typography>
          </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <form
                id='modal-edit-description'
                sx={{ mt: 5 }}
                className='form'
                onSubmit={handleEvaluationSubmit}
                xs={2} sm={4} md={4}
              >
                <Grid container direction='row' alignItems='center' justifyContent='center'>
                  <Grid item xs={12} sx={{ mt: 2, width:200 }}>
                    <TextField
                      fullWidth
                      label='Lugar de procedencia? / Where are you from?'
                      type='text'
                      placeholder='Lugar de procedencia? / Where are you from?'
                      name='location'
                      autoComplete='off'
                      value={location}
                      onChange={handleSetEvaluation}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2, width:200 }}>
                    <TextField
                      sx={{ width: '100%'}}
                      label='Permitenos saber tu experiencia viajando con VIKA TURISMO Y TRANSPORTE / Let us know your experience traveling with VIKA TOURISM AND TRANSPORT '
                      type="text"
                      placeholder="Permitenos saber tu experiencia viajando con VIKA TURISMO Y TRANSPORTE / Let us know your experience traveling with VIKA TOURISM AND TRANSPORT "
                      name='comentario'
                      autoComplete='off'
                      multiline
                      rows={6}
                      value={comentario}
                      onChange={handleSetEvaluation}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2, width:200, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <h3>Tu Calificación:</h3>
                    <Rating
                      onClick={ handleRating }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt:2}}>
                    <Button
                      variant='contained'
                      fullWidth
                      type='submit'
                    >
                      Enviar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
    </Grid>
  )
}
